import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import vodafone from '../assest/vodafone.png'
import paypal from '../assest/paypal.png'

function Contact () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي

    useEffect(() => {
        const inputField = document.querySelectorAll('.myInput');

        inputField.forEach((input) => {
            input.addEventListener('input', function () {
                if (input.value) {
                    // إذا كان هناك نص في الحقل
                    input.style.color = '#fff'; // تعيين اللون إلى الأخضر
                } else {
                    // إذا كان الحقل فارغًا
                    input.style.color = 'initial'; // إعادة تعيين اللون إلى القيمة الافتراضية
                }
            });
        })
    }, [])

    return (
        <section className='contact' id='contact'>
            <div className="container">
                <h2 className="title">
                    <span>اتصل بنا</span>
                    <p>اتصل بنا</p>
                </h2>

                <div className="contact-content">
                    <form action={`${process.env.REACT_APP_PROJECTS_API}components/insert_message.php`} method='post' >
                        <div className="input-box">
                            <div className="input-field" style={{ width: "100%" }}>
                                <div className="icon"><i class="fa fa-user"></i></div>
                                <input className='myInput' name='name' type="text" placeholder='الاسم بالكامل' required />
                            </div>
                        </div>

                        <div className="input-box">
                            <div className="input-field">
                                <div className="icon"><i class="fa fa-phone"></i></div>
                                <input className='myInput' name='phone' type="phone" placeholder='رقم الهاتف' required />
                            </div>
                            <div className="input-field">
                                <div className="icon email"><i class="fa fa-envelope"></i></div>
                                <input className='myInput' name='email' type="email" placeholder='البريد الالكتروني' required />
                            </div>
                        </div>

                        <div className="textarea-field">
                            <div className="icon message"><i class="fa fa-comment"></i></div>
                            <textarea className='myInput' name="message" cols={30} rows={10} placeholder='الرسالة' required></textarea>
                        </div>

                        <div className="btn-box btns">
                            <button type='submit' name='send' class="button type1">
                                <span class="btn-txt"><a>ارسال</a></span>
                            </button>
                        </div>
                    </form>



                    <div className="contact-social">
                        <span>
                            <a href='https://www.facebook.com/abullatiefcf' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-facebook-f"></i>
                                <i class="fa fa-facebook-f hover"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://api.whatsapp.com/send/?phone=201006642012' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-whatsapp"></i>
                                <i class="fa fa-whatsapp hover"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
