import { configureStore } from "@reduxjs/toolkit"
import showSlice from "./slices/show-slice"
import aboutSlice from "./slices/about-slice"
import reviewsSlice from "./slices/reviews-slice"
import visitorSlice from "./slices/visitor-slice"
import colorSlice from "./slices/color-slice"
import logoSlice from "./slices/logo-slice"
import servicesSlice from "./slices/services-slice"
import projectsSlice from "./slices/projects-slice"
import partnersSlice from "./slices/partners-slice"
import blogsSlice from "./slices/blogs-slice"
import newsSlice from "./slices/news-slice"



export const store = configureStore({
    reducer: {
        show: showSlice,
        about: aboutSlice,
        projects: projectsSlice,
        blogs: blogsSlice,
        partners: partnersSlice,
        reviews: reviewsSlice,
        news: newsSlice,
        visitor: visitorSlice,
        color: colorSlice,
        logo: logoSlice,
        services: servicesSlice,
    }
})