import React, { useEffect, useState } from 'react'
import logos from '../assest/logo.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVisitor } from '../rtk/slices/visitor-slice';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchLogo } from '../rtk/slices/logo-slice';

function Navbar () {

    setTimeout(() => {
        if (!sessionStorage.getItem('loading')) {
            const loader = document.querySelector('.loader');
            loader.classList.add('none');
            document.documentElement.style.overflow = 'auto';
            sessionStorage.setItem('loading', 1);
        }
    }, 4000)
    if (sessionStorage.getItem('loading') == 1) {
        setTimeout(() => {
            const loader = document.querySelector('.loader');
            loader.classList.add('none');
            document.documentElement.style.overflow = 'auto';
        }, 0)
    }

    setTimeout(() => {
        const container = document.querySelector('.container');
        const menu = document.querySelector('.menu');
        menu.addEventListener('click', () => {
            container.classList.toggle("active")
            menu.classList.toggle('active')
        })

        const links = document.querySelectorAll(".links li a")
        links.forEach((link) => {
            link.addEventListener("click", () => {
                links.forEach((li) => li.classList.remove("active"))
                link.classList.add("active");
            })
        })
    }, 4000)



    const dispatch = useDispatch();
    const visitor = useSelector((state) => state.visitor.visitor);
    const color = useSelector((state) => state.color.color);
    const logo = useSelector((state) => state.logo.logo);

    // window.onscroll = () => {
    //     const header = document.querySelector("header")
    //     if (window.scrollY > 100) {
    //         header.classList.add("active")
    //     } else {
    //         header.classList.remove("active")
    //     }

    //     const container = document.querySelector(".container")
    //     const menu = document.querySelector(".menu")
    //     container.classList.remove("active")
    //     menu.classList.remove("active")
    // }

    // window.addEventListener("DOMContentLoaded", () => {
    //     const container = document.querySelector('.container');
    //     const menu = document.querySelector('.menu');
    //     menu.addEventListener('click', () => {
    //         container.classList.toggle("active")
    //         menu.classList.toggle('active')
    //     })

    //     const links = document.querySelectorAll(".links li a")
    //     links.forEach((link) => {
    //         link.addEventListener("click", () => {
    //             links.forEach((li) => li.classList.remove("active"))
    //             link.classList.add("active");
    //         })
    //     })
    // })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    // useEffect(() => {
    //     return () => {
    //         const container = document.querySelector('.container');
    //         const menu = document.querySelector('.menu');
    //         menu.addEventListener('click', () => {
    //             container.classList.toggle("active")
    //             menu.classList.toggle('active')
    //         })

    //         const links = document.querySelectorAll(".links li a")
    //         links.forEach((link) => {
    //             link.addEventListener("click", () => {
    //                 links.forEach((li) => li.classList.remove("active"))
    //                 link.classList.add("active");
    //             })
    //         })
    //     }
    // }, [])

    useEffect(() => {

        // fetch color
        dispatch(fetchColor())
        // fetch logo
        dispatch(fetchLogo())

        // fetch visitor
        dispatch(fetchVisitor())
    }, [])

    if (visitor.length > 0) {
        if (!sessionStorage.getItem('visit')) {
            // add visitor
            const addVisitor = () => {
                fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?visitor=${visitor['0'].total}}`, { method: 'POST' })
            }
            sessionStorage.setItem('visit', 1)
            addVisitor()
        }
    }


    return (
        <header>
            <div className="container">
                <a className='logo' href="#"><img src={logo.length > 0 ? `${process.env.REACT_APP_PROJECTS_API}assest/imgs/${logo[0].logo}` : logos} alt="" /></a>
                <ul className='links'>
                    <li><a href="#home" className='active'>الرئيسية</a></li>
                    <li><a className='size-about' href="#about">من نحن</a></li>
                    <li><a href="#services">الخدمات</a></li>
                    <li><a href="#projects">المشاريع</a></li>
                    {/* <li><a href="#reviews">التقييمات</a></li> */}
                </ul>
                <button class="button type1">
                    <span class="btn-txt"><a href="#contact">إتصل بنا</a></span>
                </button>
                <div className="menu">
                    <span className='span1'></span>
                    <span className='span2'></span>
                    <span className='span3'></span>
                </div>
            </div>
        </header>
    )
}

export default Navbar
