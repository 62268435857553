import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import service from '../assest/des1.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchBlogs } from '../rtk/slices/blogs-slice';

function Blog () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };


    const dispatch = useDispatch();
    const blogs = useSelector((state) => state.blogs.blogs);
    const color = useSelector((state) => state.color.color);

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        // fetch color
        dispatch(fetchColor());

        // fetch blogs
        dispatch(fetchBlogs());

    }, [])

    return (
        <section className='projects service' id='projects'>
            <div className="container">
                <h2 className="title">
                    <span>التدوينات</span>
                    <p style={{ textTransform: "capitalize" }}>التدوينات</p>
                </h2>

                {blogs.map((blog) => {
                    return (
                        blog['id'] == id ?
                            <div className="card-service">
                                <div className="img">
                                    <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${blog.image}`} alt="" />
                                </div>
                                <div className="content">
                                    <div className="box">
                                        <h3>{blog.title}</h3>
                                        <p>
                                            {blog.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            : null
                    )
                })}


                <Link className='exit' to={`/blogs`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default Blog
