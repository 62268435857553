import React, { useEffect, useState } from 'react'
import review1 from '../assest/review1.jpg'
import review2 from '../assest/review2.jpg'
import review3 from '../assest/review3.jpg'
import review4 from '../assest/review4.jpg'
import review5 from '../assest/review5.jpg'
import review6 from '../assest/review6.jpg'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux'
import { fetchReviews } from '../rtk/slices/reviews-slice'

function Memories () {
    const dispatch = useDispatch();
    const reviews = useSelector((state) => state.reviews.reviews);

    useEffect(() => {
        // fetch reviews
        dispatch(fetchReviews());
    }, [])

    return (
        <section className='memories' id='reviews'>
            <div className="container">
                <h2 className="title">
                    <span>التقيمات</span>
                    <p>التقييمات</p>
                </h2>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    initialSlide={2}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination, Autoplay]}
                    className="mySwiper"

                    data-aos="zoom-in-down" data-aos-duration="1500"
                >
                    {reviews.map((review) => {
                        return (
                            <SwiperSlide key={review.id}>
                                <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${review.img}`} />
                            </SwiperSlide>
                        )
                    })}

                </Swiper>
            </div>
        </section >
    )
}

export default Memories
